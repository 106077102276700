<mat-form-field class="equipment-dropdown" appearance="outline" floatLabel="always">
    <mat-label>{{ 'equipment.list.table.heading.type' | translate }}</mat-label>
    <mat-select
        [disabled]="originalDataSource.length === 0"
        data-automation="equipmentDropdown"
        [value]="selectedFilters"
        multiple
    >
        <mat-option
            *ngFor="let type of equipmentTypes"
            [value]="type.name"
            (onSelectionChange)="changeEquipmentFilter($event)"
            >{{ type.displayLabel }}</mat-option
        >
    </mat-select>
</mat-form-field>

<enel-tree-display
  *ngIf="selectedView === 'equipment' && !loading"
  [treeData]="dataSource"
  [showSearch]="true"
  [selectedNodes]='selectedKeys'
  [expandedNodes]='expandedKeys'
  [showControls]='true'
  [checkbox]='true'
  [hideIrrelevant]="true"
  [disabledNodeDefinitions]='disabledNodeDefinitions'
  labelIdentifier="displayLabel"
  typeIdentifier="spaceType"
  [hideDisabledCheckbox]='true'
  [iconIdentifier]='"equipmentType"'
  [iconDefinitions]='ICON_DEFINITION'
  [select]='selectNode'
  (selection$)='onSelect($event)'
>
</enel-tree-display>

<div class="spinner-container" *ngIf="loading">
    <mat-spinner mode="indeterminate" diameter="60"></mat-spinner>
</div>
<ng-container *ngIf="noEquipment && !loading">
    <div class="list-empty">
        {{ 'equipment.list.none_configured' | translate }}
    </div>
</ng-container>

<div class="create-button-container">
    <button
        (click)="routeToCreatePage()"
        id="create-button"
        class="icon-btn x-btn-primary"
    >
        <mat-icon>add</mat-icon>
        <span class="btn-text">{{ 'equipment.list.button.create_equipment' | translate }}</span>
    </button>
</div>
