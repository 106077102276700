<app-global-alert></app-global-alert>
<global-nav [multiSelect]="false" [navUrl]="navUrl"></global-nav>
<mat-sidenav-container class="app-container">
    <mat-sidenav
      #sidenav
      fixedInViewport="true"
      fixedTopGap="60"
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [opened]="sidenavOpen"
      (openedChange)='triggerResize()'
      class="sidenav-container"
    >
        <header class="sidenav-header">
            <div class="left-column">
                <h1 class="page-title ellipsis" data-automation="listTitle"
                    title="{{ 'equipment.list.tabs.configured_equipment' | translate }}">
                    {{ 'equipment.list.tabs.configured_equipment' | translate }}
                </h1>
            </div>

            <div class="right-column">
                <button mat-icon-button color="accent" aria-label="Menu" class="toggle-sidenav"
                    data-automation="toggle-sidenav" (click)="sidenav.toggle()">
                    <mat-icon>double_arrow</mat-icon>
                </button>
            </div>
        </header>
        <div class="sidenav-component-container">
          <app-list></app-list>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <div class="main-container">
            <router-outlet></router-outlet>
        </div>
      <ngx-global-contact-footer></ngx-global-contact-footer>
    </mat-sidenav-content>
</mat-sidenav-container>
